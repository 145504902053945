import $ from 'jquery';

const WIDTHS = [2560, 1920, 1440, 1366, 1080, 768, 500];
const SIZES = {
    2560: 1440,
    1920: 1080,
    1440: 900,
    1366: 768,
    1080: 675,
    768: 512,
    500: 300
}

function devicePixelRatio_() {
    window.devicePixelRatio ? window.devicePixelRatio : 1;
}

function getNearestWidth(width) {
    let imageWidth = 2560;
    //console.log(`finding with for ${width}`);
    //for (var w of Array.from(WIDTHS)) {
    //console.log(w);
    //}
    for (let w of Array.from(WIDTHS)) { if (width <= w) { imageWidth = w; } }
    //console.log(`found width: ${imageWidth}`);
    return imageWidth;
}

function getNearestWindowSize() {
    const dpr = devicePixelRatio_();
    const windowWidth = $(window).width() * dpr;
    const w = getNearestWidth(windowWidth);
    const h = SIZES[`${w}`];
    console.log(w + 'x' + h);
    return {w, h};
}

function getThumbnailSize() {
    const dpr = devicePixelRatio_();
    if (dpr === 1) {
        return 768;
    }

    return 1080;
}

function thumbnailUrl(url) {
    return url.replace('img', `img/${getThumbnailSize()}`);
}

export {
    getNearestWidth,
    getNearestWindowSize,
    getThumbnailSize,
    thumbnailUrl
};
