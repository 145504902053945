import '@sass/main.scss';
console.log("Hello");

import { loadSlides } from 'slides';
import $ from 'jquery';

let minWidth;

if (minWidth == null) {
    minWidth = $(window).width();
}

if ($('#lightslider').length) {
    loadSlides();
    $(window).on('resize orientationChange', function() {
        console.log('on resize orientationChange');
        const width = $(window).width();
        if ((minWidth != null) && (minWidth < width)) {
            minWidth = width;
            loadSlides();
        }
    });
}

if ($('#map').length) {
    const map = import('map');
    map.initMap();
}
