import $ from 'jquery';
import { getNearestWidth } from 'images';
import 'lightslider';

let slideshowStarted = false;

function isTouchDevice() {
    return 'ontouchstart' in window;
}

function startSlideshow() {
    const lsEl = $("#lightslider");
    const mode = isTouchDevice() ? 'slide' : 'fade';
    const speed = mode === 'slide' ? 400 : 750;
    return lsEl.lightSlider({
        mode,
        gallery: false,
        pager: false,
        item: 1,
        loop: true,
        slideMargin: 0,
        enableDrag: isTouchDevice(),
        enableTouch: true,
        pauseOnInteract: true,

        auto: true,
        pause: 4000,
        speed,
    });
}

function loadSlides() {
    const startTime = new Date;
    //console.log("loading slides")
    const dpr = window.devicePixelRatio ? window.devicePixelRatio : 1;
    const windowWidth = $(window).width() * dpr;
    const slideWidth = getNearestWidth(windowWidth);
    //console.log('slide width: ' + slideWidth)
    const lazySlides = document.querySelectorAll('.slide[data-src]');
    const numLazySlides = lazySlides.length;
    let numSlidesLoaded = 0;
    let i = 0;
    while (i < numLazySlides) {
        const slide = lazySlides[i];
        let src = slide.getAttribute('data-src');
        src = src.replace('img', `img/${slideWidth}`);

        slide.setAttribute('src', src);

        slide.onload = function() {
            numSlidesLoaded++;
            if (!slideshowStarted && (numSlidesLoaded === numLazySlides)) {
                // All slides loaded
                slideshowStarted = true;
                const slider = startSlideshow();
                const endTime = new Date;
                const timeDiff = endTime - startTime;
                if (timeDiff >= 4000) {
                    slider.goToNextSlide();
                } else {
                    setTimeout(() => slider.goToNextSlide()
                        , 4000 - timeDiff);
                }
            }
        };

        i++;
    }
}

export { loadSlides }
